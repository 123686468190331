import { useState, useEffect, useContext } from "react";
import { selectionsContext } from "contexts/selections";

import { Box, Button, Spinner, Grid, Text } from "@chakra-ui/react";
import { SearchLuhah, Luhah } from "components/home";
import {
  Container,
  CustomButton,
  CustomSelect,
  Loading,
} from "components/shared";
import { sortingSelections } from "components/shared/selections";
import { getAllListings } from "apis/lu7ah";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "react-query";

const Home = () => {
  const { ref, inView } = useInView();
  const all = { name: "الكل", id: "" };

  const {
    chars_ar_en,
    numbers: numbersSelections,
    type,
    number_type,
  } = useContext(selectionsContext);

  const chars_arabic = Object.keys(chars_ar_en);
  const chars_en = Object.values(chars_ar_en);

  let numberSelectionsArray = [];
  let chars = [];

  numbersSelections?.map((e) => {
    numberSelectionsArray.push({ id: e, name: e });
  });

  chars_arabic?.map((e, i) => {
    chars.push({ name: e, id: chars_en[i] });
  });

  //select type state
  const [selectType, setSelectType] = useState(all);
  const [selectNumberType, setSelectNumberType] = useState(all);
  //select letters state
  const [selectFirstLetter, setSelectFirstLetter] = useState(all);
  const [selectSecondLetter, setSelectSecondLetter] = useState(all);
  const [selectThirdLetter, setSelectThirdLetter] = useState(all);
  //select numbers state
  const [selectNumberOne, setSelectNumberOne] = useState(all);
  const [selectNumberTwo, setSelectNumberTwo] = useState(all);
  const [selectNumberThree, setSelectNumberThree] = useState(all);
  const [selectNumberFour, setSelectNumberFour] = useState(all);
  //select sort state
  const [sortingByDate, setSortingByDate] = useState(sortingSelections[0]);

  const handleSortingByDate = (e) => {
    setSortingByDate(e);
  };

  const {
    isLoading,
    isError,
    error,
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [
      "getAllListings",
      selectFirstLetter.id,
      selectSecondLetter.id,
      selectThirdLetter.id,
      selectNumberType.id,
      selectNumberOne.id,
      selectNumberTwo.id,
      selectNumberThree.id,
      selectNumberFour.id,
      selectType.id,
      sortingByDate.id,
    ],
    getAllListings,
    {
      // getPreviousPageParam: (firstPage) => firstPage.previousId ?? undefined,
      // getNextPageParam: (lastPage) => lastPage.nextId ?? undefined,

      getNextPageParam: (_lastPage, pages) => {
        if (pages.length < pages[0]?.data?.meta?.last_page) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
    }
  );

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  // const { data } = useQuery("getAllListings", getAllListings);

  return (
    <Box>
      <SearchLuhah
        selectType={selectType}
        setSelectType={setSelectType}
        selectNumberType={selectNumberType}
        setSelectNumberType={setSelectNumberType}
        selectFirstLetter={selectFirstLetter}
        setSelectFirstLetter={setSelectFirstLetter}
        selectSecondLetter={selectSecondLetter}
        setSelectSecondLetter={setSelectSecondLetter}
        selectThirdLetter={selectThirdLetter}
        setSelectThirdLetter={setSelectThirdLetter}
        selectNumberOne={selectNumberOne}
        setSelectNumberOne={setSelectNumberOne}
        selectNumberTwo={selectNumberTwo}
        setSelectNumberTwo={setSelectNumberTwo}
        selectNumberThree={selectNumberThree}
        setSelectNumberThree={setSelectNumberThree}
        selectNumberFour={selectNumberFour}
        setSelectNumberFour={setSelectNumberFour}
        typeSelections={[all, ...type]}
        numberTypeSelections={[all, ...number_type]}
        charsSelections={[all, ...chars]}
        number_selections={[all, ...numberSelectionsArray]}
      />

      <Container>
        <Box mb="45px">
          <CustomSelect
            placeholder=""
            select={sortingByDate}
            handleSelect={handleSortingByDate}
            selections={sortingSelections}
          />
        </Box>
        {isLoading ? (
          <Grid placeItems="center" my="50px">
            <Loading />
          </Grid>
        ) : data?.pages[0]?.data?.data.length > 0 ? (
          <>
            <Grid
              gridTemplateColumns={[
                "repeat(1,1fr) ",
                "repeat(2,1fr) ",
                "repeat(3,1fr) ",
                "repeat(3,1fr) ",
              ]}
              gap="20px">
              {data?.pages?.map((p) => {
                return (
                  <>
                    {p?.data?.data?.map((e) => (
                      <Box key={e.id}>
                        <Luhah
                          id={e.id}
                          charsAr={e.chars_ar}
                          charsEn={e.chars_en}
                          numbers={e.numbers}
                          location={e?.city_name}
                          date={e.created_at}
                          price={e.price}
                          type={e.type}
                          underBidding={e.under_bidding}
                        />
                      </Box>
                    ))}
                  </>
                );
              })}
            </Grid>

            <Button
              ref={ref}
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage}
              minH="38px"
              maxH="100%"
              fontWeight="normal"
              bg="bg.darkBlue"
              color="white"
              border="none"
              borderRadius="5px"
              w="100%"
              _hover={{}}
              _focus={{ outline: "none" }}
              _active={{}}
              display="flex"
              alignItems="center"
              my="30px">
              {isFetchingNextPage ? <Spinner me="5px" /> : null}
              تحميل المزيد
            </Button>
          </>
        ) : (
          <Text textAlign="center" fontSize="20px" my="50px">
            لا يوجد بيانات لعرضها
          </Text>
        )}
      </Container>
    </Box>
  );
};

export default Home;
