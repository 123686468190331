import { useState, useEffect } from "react";
import { Box, Grid, useToast } from "@chakra-ui/react";
import Layout from "layout";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import i18next from "i18n";
import { configureAxios } from "axios-config";
import { selectionsContext } from "contexts/selections";
import isFilter from "contexts/filter";
import { userContext } from "contexts/user";
import { getSelections } from "apis/lu7ah";
import { useQuery } from "react-query";
import { Loading, CustomToast } from "components/shared";
import { getUserData } from "apis/user";
import {
  Payment,
  Terms,
  Privacy,
  Messages,
  Favorites,
  EditAdd,
  ConfirmOtp,
  Home,
  About,
  SignIn,
  SignUp,
  ContactUs,
  ShowAd,
  AddAd,
  User,
  CalculateCommission,
} from "pages";

const App = () => {
  const [contextData, setContextData] = useState(null);
  const [isExit, setIsExit] = useState(false);
  const [filterData, setFilterData] = useState({ isFilterExist: false });
  const [token, setToken] = useState(localStorage.getItem("lu7ah_token"));
  const toast = useToast();

  const user = JSON.parse(localStorage.getItem("lu7ah_user"));

  const { refetch, isLoading: loadingUserData } = useQuery(
    "get-user-data",
    getUserData,
    {
      enabled: false,
      onSuccess: (res) => {
        localStorage.setItem("lu7ah_user", JSON.stringify(res?.data?.customer));
      },
    }
  );

  useEffect(() => {
    if (user && !user?.is_profile_ready) {
      refetch();
    }
    i18next.changeLanguage("ar");
  }, []);

  const { data, isLoading } = useQuery("getSelections", getSelections, {
    onSuccess: (res) => {
      setContextData(res.data);
    },
  });
  configureAxios(token);

  const exit = () => {
    localStorage.removeItem("lu7ah_user");
    setIsExit(true);
    toast({
      render: (props) => {
        return (
          <CustomToast
            title={"تسجيل الخروج"}
            description="تم تسجيل الخروج بنجاح"
            status="success"
            onClose={props.onClose}
          />
        );
      },
    });
  };

  if (isLoading || loadingUserData) {
    return (
      <Grid minH="100vh" placeItems="center">
        <Loading />
      </Grid>
    );
  }
  return (
    <selectionsContext.Provider value={contextData}>
      <userContext.Provider value={{ exit, setToken }}>
        <isFilter.Provider value={{ filterData, setFilterData }}>
          <Layout>
            <Routes>
              <Route path="/signup" element={<SignUp />} />

              {!user && <Route path="/signin" element={<SignIn />} />}

              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/commission" element={<CalculateCommission />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="otp" element={<ConfirmOtp />} />
              <Route path="/show-adds/:id" element={<ShowAd />} />
              <Route path="/pay" element={<Payment />} />
              {user && user?.is_profile_ready && (
                <>
                  <Route path="/add-adds" element={<AddAd />} />
                  <Route path="/user" element={<User />} />
                  {/* <Route path="/messages" element={<Messages />} /> */}
                  <Route path="/favorites" element={<Favorites />} />
                  <Route path="/edit/:id" element={<EditAdd />} />
                </>
              )}

              <Route
                path="*"
                element={
                  <Navigate
                    to={
                      user && user?.is_profile_ready == false
                        ? "/signup"
                        : !user
                        ? "/signin"
                        : "/"
                    }
                  />
                }
              />
            </Routes>
          </Layout>
        </isFilter.Provider>
      </userContext.Provider>
    </selectionsContext.Provider>
  );
};

export default App;
