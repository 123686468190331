import { useState, memo, useCallback, useContext } from "react";
import { selectionsContext } from "contexts/selections";
import {
  Box,
  Text,
  Grid,
  Textarea,
  Flex,
  Checkbox,
  Link as ChakraLink,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import {
  Container,
  CustomSelect,
  CustomInput,
  CustomButton,
  ErrorMsg,
  CustomToast,
} from "components/shared";
import { useMutation } from "react-query";
import { createList } from "apis/lu7ah";
import { useNavigate } from "react-router-dom";
// import {
//   typeSelection,
//   numberTypeSelections,
//   costType,
//   cities,
//   transportCost,
// } from "components/shared/selections";

import { LuhahInfo, PrivacyModal } from "components/add-adds";
import { Formik, Form, FieldArray } from "formik";
import { addAddValidation } from "validations/add-ads";

const AddAd = memo(() => {
  const select = { name: "اختر", id: "" };
  const user = JSON.parse(localStorage.getItem("lu7ah_user"));

  const navigate = useNavigate();

  const { type, number_type, cities, cost_transfer } =
    useContext(selectionsContext);

  const toast = useToast();

  //const [showPrivacy, setShowPrivacy] = useState(false);

  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [showPhone, setShowPhone] = useState(true);

  const [selectType, setSelectType] = useState("");
  const [selectNumberType, setSelectNumberType] = useState(select);
  const [selectCity, setSelectCity] = useState("");
  const [selectTransport, setSelectTransport] = useState("");

  //select numbers
  const [selectNumberOne, setSelectNumberOne] = useState(select);
  const [selectNumberTwo, setSelectNumberTwo] = useState(select);
  const [selectNumberThree, setSelectNumberThree] = useState(select);
  const [selectNumberFour, setSelectNumberFour] = useState(select);

  const [isPriceEnter, setIsPriceEnter] = useState(true);

  const handleSelectType = (e, formik) => {
    setSelectType(e);
    formik.setFieldValue("type", e.id);
  };

  const handleSelectNumberType = useCallback((e, formik) => {
    formik.setFieldValue("numberType", e.id);

    if (e.id == "" || e.id == 4) {
      formik.setFieldValue("numbersList", ["", "", "", ""]);
    } else if (e.id == 1) {
      formik.setFieldValue("numbersList", [""]);
    } else if (e.id == 2) {
      formik.setFieldValue("numbersList", ["", ""]);
    } else if (e.id == 3) {
      formik.setFieldValue("numbersList", ["", "", ""]);
    }

    setSelectNumberOne(select);
    setSelectNumberTwo(select);
    setSelectNumberThree(select);
    setSelectNumberFour(select);
    setSelectNumberType(e);
  }, []);

  const handleSelectCity = (e, formik) => {
    setSelectCity(e);
    formik.setFieldValue("city", e.id);
  };

  const handleSelectTransport = (e, formik) => {
    setSelectTransport(e);
    formik.setFieldValue("cost", e.id);
  };

  // const handleSelectCostType = (e) => {
  //   setSelectCostType(e);
  // };

  const { mutate, isSuccess, isLoading } = useMutation(
    "create-list",
    createList,
    {
      onSuccess: () => {
        toast({
          render: (props) => {
            return (
              <CustomToast
                title={"اضافة لوحة"}
                description="تم اضافة اللوحة بنجاح"
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
        navigate("/");
      },
      onError: () => {
        toast({
          render: (props) => {
            return (
              <CustomToast
                title={"اضافة لوحة"}
                description="حدث خطأ ما"
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  return (
    <Box>
      <Container>
        <Text fontSize="19px" fontWeight="bold" color="#405fa1" mb="25px">
          إضافة اعلان جديد
        </Text>
        <Formik
          initialValues={{
            type: selectType?.id,
            numberType: selectNumberType?.id,
            city: selectCity?.id,
            cost: selectTransport?.id,
            phone: user?.mobile,
            price: "",
            notes: "",
            letterOne: "",
            letterTwo: "",
            letterThree: "",
            numbersList: ["", "", "", ""],
          }}
          validationSchema={addAddValidation}
          onSubmit={(values, formik) => {
            if (
              values.letterOne == "" ||
              values.letterTwo == "" ||
              values.letterThree == ""
            ) {
              toast({
                render: (props) => {
                  return (
                    <CustomToast
                      title="ادخال الحروف"
                      description="يجب ادخال جميع الحروف"
                      status="error"
                      onClose={props.onClose}
                    />
                  );
                },
              });
            } else {
              if (isPriceEnter && values.price == "") {
                toast({
                  render: (props) => {
                    return (
                      <CustomToast
                        title={"اضافة سعر"}
                        description="يجب ادخال سعر"
                        status="error"
                        onClose={props.onClose}
                      />
                    );
                  },
                });
              } else {
                mutate({
                  type: values.type,
                  char1: values.letterOne,
                  char2: values.letterTwo,
                  char3: values.letterThree,
                  num1: values.numbersList[0].toString()[0],
                  num2: values.numbersList[1]
                    ? values.numbersList[1].toString()[0]
                    : "",
                  num3: values.numbersList[2]
                    ? values.numbersList[2].toString()[0]
                    : "",
                  num4: values.numbersList[3]
                    ? values.numbersList[3].toString()[0]
                    : "",
                  city: values.city,
                  mobile: values.phone,
                  price: values.price,
                  comment: values.notes,
                  cost_transfer: values.cost,
                  is_active: true,
                  number_type: values.numberType,
                  is_hidden: false,
                  hidden_my_number: showPhone ? 0 : 1,
                  is_sold: false,
                  under_bidding: !isPriceEnter,
                  is_special: true,
                });
              }
            }
          }}>
          {(formik) => {
            return (
              <Form>
                <FieldArray
                  name="numbersList"
                  render={(arrayHelpers) => (
                    <>
                      <Grid
                        gridTemplateColumns={[
                          "repeat(1,1fr)",
                          "repeat(2,1fr)",
                          "",
                          "",
                        ]}
                        gap="25px"
                        mb="25px">
                        <Box>
                          <CustomSelect
                            label="نوع اللوحة"
                            placeholder="اختر"
                            select={selectType}
                            handleSelect={(e) => handleSelectType(e, formik)}
                            selections={type}
                            colorLabel="black"
                          />
                          {formik.errors.type && (
                            <ErrorMsg text={formik.errors.type} />
                          )}
                        </Box>

                        <Box>
                          <CustomSelect
                            label="نوع الارقام"
                            placeholder="اختر"
                            select={selectNumberType}
                            handleSelect={(e) => {
                              handleSelectNumberType(e, formik);
                            }}
                            selections={number_type}
                            colorLabel="black"
                          />
                          {formik.errors.numberType && (
                            <ErrorMsg text={formik.errors.numberType} />
                          )}
                        </Box>
                      </Grid>
                      <LuhahInfo
                        selectNumberType={selectNumberType}
                        type={selectType}
                        formik={formik}
                        selectNumberOne={selectNumberOne}
                        selectNumberTwo={selectNumberTwo}
                        selectNumberThree={selectNumberThree}
                        selectNumberFour={selectNumberFour}
                        setSelectNumberOne={setSelectNumberOne}
                        setSelectNumberTwo={setSelectNumberTwo}
                        setSelectNumberThree={setSelectNumberThree}
                        setSelectNumberFour={setSelectNumberFour}
                      />
                    </>
                  )}
                />

                <Box mb="25px">
                  <CustomSelect
                    label="المدينة"
                    placeholder="اختر مدينة"
                    select={selectCity}
                    handleSelect={(e) => {
                      handleSelectCity(e, formik);
                    }}
                    selections={cities}
                    colorLabel="black"
                  />
                  {formik.errors.city && <ErrorMsg text={formik.errors.city} />}
                </Box>

                {/* <Box mb="0" opacity="0.9">
                  <CustomInput
                    label="رقم الجوال"
                    value={formik.values.phone}
                    onChange={formik.handleChange("phone")}
                    onBlur={formik.handleBlur("phone")}
                    disabled
                  />
                  {formik.errors.phone && formik.touched.phone && (
                    <ErrorMsg text={formik.errors.phone} />
                  )}
                </Box> */}

                <Text mb="5px" opacity={!isPriceEnter ? "0.4" : "1"}>
                  ادخل السعر
                </Text>
                <Flex
                  alignItems="flex-start"
                  mb="25px"
                  flexWrap={["wrap", "nowrap", "", ""]}>
                  <Box
                    w={["100%", "60%", "", ""]}
                    me={["0", "15px", "", ""]}
                    mb={["0", "0", "", ""]}>
                    <CustomInput
                      placeholder="بالريال السعودي"
                      type="number"
                      min="0"
                      opacity={!isPriceEnter ? "0.4" : "1"}
                      disabled={!isPriceEnter}
                      value={formik.values.price}
                      onChange={formik.handleChange("price")}
                      onBlur={formik.handleBlur("price")}
                    />
                    {formik.errors.price && formik.touched.price && (
                      <ErrorMsg text={formik.errors.price} />
                    )}
                  </Box>

                  <Flex alignItems="center" mt="6px">
                    <Checkbox
                      onChange={(e) => {
                        setIsPriceEnter(!isPriceEnter);
                        formik.setFieldValue("price", "");
                      }}
                      me="10px"
                      _focus={{ boxShadow: "none" }}
                      _active={{}}
                    />
                    <Text>
                      السعر على{" "}
                      <Text as="span" color="#007bff">
                        السوم
                      </Text>
                    </Text>
                  </Flex>
                </Flex>

                <Box mb="25px">
                  <CustomSelect
                    label="تكلفة النقل"
                    placeholder="اختر"
                    select={selectTransport}
                    handleSelect={(e) => handleSelectTransport(e, formik)}
                    selections={cost_transfer}
                    colorLabel="black"
                  />
                  {formik.errors.cost && <ErrorMsg text={formik.errors.cost} />}
                </Box>

                <Box mb="25px">
                  <Text textTransform="capitalize" mb="10px">
                    ملاحظات
                  </Text>
                  <Textarea
                    h="200px"
                    resize="none"
                    _focus={{ border: "1px solid #a5c6d5", boxShadow: "none" }}
                    value={formik.values.notes}
                    onChange={formik.handleChange("notes")}
                    onBlur={formik.handleBlur("notes")}
                  />
                </Box>

                {/* <Flex alignItems="center">
                  <Checkbox
                    me="10px"
                    onChange={() => setAgreePrivacy(!agreePrivacy)}
                    _focus={{ boxShadow: "none" }}
                    _active={{}}
                  />
                  <Text>
                    الموافقة على{" "}
                    <ChakraLink
                      href="https://www.policy.aerbag.com/"
                      target="_blank"
                      // onClick={() => setShowPrivacy(true)}
                      color="#007bff"
                      cursor="pointer"
                    >
                      شروط موقع لوحة
                    </ChakraLink>
                  </Text>
                </Flex> */}

                <Flex alignItems="center" mt="6px" mb="25px">
                  <Checkbox
                    isChecked={showPhone}
                    onChange={(e) => {
                      setShowPhone(!showPhone);
                    }}
                    me="10px"
                    _focus={{ boxShadow: "none" }}
                    _active={{}}
                  />
                  <Text>اظهار رقم الهاتف</Text>
                </Flex>

                <CustomButton
                  name="اضافة"
                  mt="50px"
                  mb="60px"
                  display="flex"
                  alignItems="center"
                  type="submit"
                  loading={isLoading && <Spinner me="5px" />}
                  disabled={isLoading}
                />
                {/* <PrivacyModal show={showPrivacy} setShow={setShowPrivacy} /> */}
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Box>
  );
});

export default AddAd;
